<template>
  <div class="elv-landing-project-plan-page">
    <div class="elv-landing-project-plan-page-header">
      <img
        src="@/assets/img/reports/default-entity-logo.png"
        class="elv-chain-icon-content__logo"
        :onerror="useDefaultImage"
      />
      <span>{{ t('button.createProject') }}</span>
    </div>
    <el-scrollbar class="elv-landing-project-plan-page-content">
      <div class="elv-landing-project-plan-view-content">
        <p class="elv-landing-project-plan__title">{{ t('report.welcomeUser', { userName: user?.name }) }}!</p>
        <p class="elv-landing-project-plan__msg">{{ t('message.chooseYourSubscriptionPlan') }}</p>
        <div class="elv-landing-project-plan-box">
          <div class="elv-landing-project-plan-empty"></div>
          <div v-for="(item, index) in projectPlanList" :key="index" :class="`elv-landing-project-plan-${item.name}`">
            <span class="elv-landing-project-plan-name">{{ currentPlanInfo(item).planName }}</span>
            <span class="elv-landing-project-plan-msg" :class="`elv-landing-project-plan-msg-${item.name}`">{{
              t(item.msg)
            }}</span>
            <span v-if="currentPlanInfo(item).price" class="elv-landing-project-plan-money"
              >{{ currentPlanInfo(item).price }}
              <span v-if="item.priceSuffix.en" class="elv-landing-project-plan-money-suffix">{{
                getLocaleShowInfo(item.priceSuffix)
              }}</span></span
            >
            <span
              v-if="item.tag"
              class="elv-landing-project-plan-tag"
              :class="`elv-landing-project-plan-tag-${item.name}`"
              ><span v-if="item.name === 'standard'" class="fire">🔥</span>{{ t(item.tag) }}</span
            >
            <el-button
              v-if="item.btnTxt"
              :class="{ 'elv-landing-project-plan-button-checked': currentCheckProjectPlan === item.value }"
              round
              @click="onCheckProjectPlan(item)"
              >{{ t(item.btnTxt) }}
              <SvgIcon
                name="is"
                width="14"
                height="14"
                :fill="currentCheckProjectPlan === item.value ? '#ffffff' : '#838D95'"
            /></el-button>
          </div>
        </div>
        <p class="elv-landing-project-plan__item-title">{{ t('title.historicalTransactions') }}</p>
        <div class="elv-landing-project-plan-box">
          <span class="elv-landing-project-plan-historical-content">
            {{ t('project.nav.transactions') }}
          </span>
          <span
            v-for="(item, index) in projectPlanList"
            :key="index"
            class="elv-landing-project-plan-historical-content"
            >{{ currentPlanInfo(item).transaction }}</span
          >
        </div>
        <p class="elv-landing-project-plan__item-tip">{{ t('message.historicalTransactionsTip') }}</p>

        <div v-for="(item, index) in planIntroduceData" :key="index" class="elv-landing-project-plan-introduce-content">
          <p class="elv-landing-project-plan__item-title">{{ getLocaleShowInfo(item.title) }}</p>
          <ProjectPlanTable :tableData="item.list" />
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script setup lang="ts">
import { judgeTalkToSalesLink } from '@/lib/utils'
import ProjectPlanTable from './ProjectPlanTable.vue'
import { useGlobalStore } from '@/stores/modules/global'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import defaultImg from '@/assets/img/reports/default-entity-logo.png'

const { t } = useI18n()
const router = useRouter()
const globalStore = useGlobalStore()
const userGlobalStore = useUserGlobalStore()
const { user } = storeToRefs(userGlobalStore)

const { projectPlanIntroduceInfo } = reactive(globalStore)
const currentCheckProjectPlan = ref('STANDARD')

const projectPlanList = [
  {
    name: 'basic',
    label: 'Basic',
    msg: 'message.monthlyPayment',
    tag: 'message.welcomeOfferForBeginners',
    btnTxt: 'button.continueWithBasic',
    priceSuffix: {
      en: '',
      zh: ''
    },
    value: 'BASIC'
  },
  {
    name: 'standard',
    label: 'Standard',
    msg: 'message.monthlyPayment',
    tag: 'common.mostPopular',
    btnTxt: 'button.continueWithStandard',
    priceSuffix: {
      en: '/10,000 transactions',
      zh: '/每 10,000 笔交易'
    },
    value: 'STANDARD'
  },
  {
    name: 'custom',
    label: 'Customized Version',
    msg: 'message.customizedPlanMsg',
    tag: '',
    btnTxt: 'button.talkToSales',
    priceSuffix: {
      en: '',
      zh: ''
    },
    value: 'CUSTOM'
  }
]

const planIntroduceData = computed(() => {
  return projectPlanIntroduceInfo.introduce
})

const currentPlanInfo = computed(() => (plan: any) => {
  const info = { planName: '', price: '', transaction: '' }
  const currentPlan = projectPlanIntroduceInfo.subscriptionPlans.find((item) => item.plan.en === plan.label)
  if (currentPlan) {
    const currentLanguage = useI18n().locale.value
    info.planName = currentLanguage === 'en' ? currentPlan?.plan.en : currentPlan?.plan?.zh || ''
    info.price = currentPlan?.price || ''
    info.transaction = currentPlan?.transactions || ''
    return info
  }
  return info
})

const getLocaleShowInfo = computed(() => (data: any) => {
  const currentLanguage = useI18n().locale.value
  return currentLanguage === 'en' ? data?.en : data?.zh || ''
})

const useDefaultImage = (event: any) => {
  event.target.src = defaultImg
}

// 选择项目版本
const onCheckProjectPlan = (item: any) => {
  if (item.value === 'CUSTOM') {
    // 跳转到联系销售页面
    judgeTalkToSalesLink(user.value?.name || '', user.value?.email)
    return
  }
  router.push({
    name: 'landing-subscription',
    state: {
      projectPlan: item.value
    }
  })
}
</script>

<style>
.elv-layout-main {
  padding: 0 !important;
}
</style>

<style lang="scss" scoped>
.elv-landing-project-plan-page {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .elv-landing-project-plan-page-header {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    font-size: 16px;
    color: #0e0f11;
    border-bottom: 1px solid #dde1e6;
    padding-left: 20px;

    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .elv-landing-project-plan-page-content {
    height: calc(100% - 48px);
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;

    .elv-landing-project-plan-view-content {
      display: flex;
      flex-direction: column;
      padding-top: 93px;
      font-family: 'Plus Jakarta Sans';
      width: 862px;
      height: fit-content;
      position: relative;

      .elv-landing-project-plan__title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 25px;
        color: #4e515a;
        margin-bottom: 16px;
      }

      .elv-landing-project-plan__msg {
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
        color: #838d95;
        margin-bottom: 16px;
      }

      .elv-landing-project-plan-box {
        width: 862px;
        border: 1px solid #dde1e6;
        border-radius: 3px;
        display: flex;
        align-items: center;
        position: relative;

        div {
          height: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-family: 'Plus Jakarta Sans';
          position: relative;

          button {
            font-family: 'Plus Jakarta Sans';
            font-size: 12px;
            font-weight: 500;
            border: 1px solid #dde1e6;
            color: #1e2024;
            position: absolute;
            bottom: 16px;

            span {
              display: flex;
              align-items: center;

              svg {
                margin-left: 4px;
              }
            }

            &:hover {
              background-color: transparent;
              border: 1px solid #dde1e6;
              color: #1e2024;
            }
          }

          .elv-landing-project-plan {
            &-name {
              font-size: 14px;
              font-weight: 700;
              color: #636b74;
              margin-bottom: 8px;
              margin-top: 32px;
            }

            &-msg {
              font-size: 11px;
              font-weight: 400;
              color: #aaafb6;

              &-custom {
                font-size: 12px;
                color: #636b75;
                width: 176px;
              }
            }

            &-money {
              font-size: 20px;
              font-weight: 600;
              line-height: 28px;
              color: #000000;
              margin-top: 8px;
              display: flex;
              align-items: center;

              .elv-landing-project-plan-money-suffix {
                font-family: 'Plus Jakarta Sans';
                font-size: 11px;
                font-style: normal;
                font-weight: 400;
                color: #636b75;
                margin-left: 4px;
              }
            }

            &-tag {
              font-size: 11px;
              font-weight: 400;
              line-height: 15px;
              margin-top: 12px;

              .fire {
                margin-right: 2px;
              }

              &-basic {
                color: #1e2024;
              }

              &-standard {
                width: 98px;
                height: 19px;
                border-radius: 15px;
                background-color: #e5edff;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #1753eb;
              }
            }

            &-button-checked {
              background-color: #1753eb;
              color: #ffffff;

              &:hover {
                background-color: #1753eb;
                color: #ffffff;
              }
            }
          }
        }

        .elv-landing-project-plan {
          &-empty {
            width: 150px;
            border-right: 1px solid #dde1e6;
          }

          &-basic {
            width: 236px;
            border-right: 1px solid #dde1e6;
          }

          &-standard {
            width: 236px;
            border-right: 1px solid #dde1e6;
          }

          &-custom {
            flex: 1;
            min-height: 0;
          }
        }

        .elv-landing-project-plan-historical-content {
          height: 67px;
          width: 236px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-weight: 600;
          color: #0e0f11;
          border-left: 1px solid #dde1e6;
          margin-bottom: 0px;

          &:first-child {
            flex: 1;
            font-weight: 500;
            border-left: none;
            background-color: #f9fafb;
          }
        }
      }

      .elv-landing-project-plan__item-title {
        font-family: 'Plus Jakarta Sans';
        font-weight: 600;
        font-size: 16px;
        color: #0e0f11;
        margin: 16px 0;
      }

      .elv-landing-project-plan__item-tip {
        width: 100%;
        margin-top: 16px;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        color: #636b75;
        white-space: pre-wrap;
      }
    }
  }
}
</style>
